<template>
  <div>
    <ul>
      <li v-for="(link, index) in links" :key="index">
        <router-link :to="link.to">{{ link.name }}</router-link>
      </li>
    </ul>
    <main>
      <router-view/>
    </main>
  </div>
</template>

<style scoped>
  ul {
    list-style: none;
    display: flex;
    padding: 0;
  }

  li {
    padding: 5px 15px 5px 0;
  }

  li a {
    text-decoration: none;
    color: black;
  }

  li a:hover {
    color: #404040;
  }
</style>


<!-- Firebase App is always required and must be first -->
<script src="https://www.gstatic.com/firebasejs/5.9.0/firebase-app.js"></script>

<!-- Add additional services that you want to use -->
<script src="https://www.gstatic.com/firebasejs/5.9.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/5.9.0/firebase-database.js"></script>
<script src="https://www.gstatic.com/firebasejs/5.9.0/firebase-firestore.js"></script>
<script src="https://www.gstatic.com/firebasejs/5.9.0/firebase-messaging.js"></script>
/* <script src="https://www.gstatic.com/firebasejs/5.9.0/firebase-functions.js"></script> */

<script>
// Firebase App is always required and must be first
var firebase = require("firebase/app");

// Add additional services that you want to use
require("firebase/auth");
require("firebase/database");
require("firebase/firestore");
require("firebase/messaging");
// require("firebase/functions");

  // Initialize Firebase
  var config = {
    apiKey: "AIzaSyBBmKQFBHphsKcRz9ucKsIfzRyT67LATfk",
    authDomain: "pwa-vue-app-2dd9f.firebaseapp.com",
    databaseURL: "https://pwa-vue-app-2dd9f.firebaseio.com",
    projectId: "pwa-vue-app-2dd9f",
    storageBucket: "pwa-vue-app-2dd9f.appspot.com",
    messagingSenderId: "435002924673"
  };
  firebase.initializeApp(config);
</script>

<script>
export default {
  data: () => ({
    links: [
      {
        name: 'Home',
        to: '/'
      },
      {
        name: 'Page 1',
        to: '/page-1'
      },
      {
        name: 'Bad Link',
        to: '/random-bad-url'
      }
    ]
  })
}
</script>
